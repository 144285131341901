import { db } from "../pages/Firebase"
import { collection, getDoc, getDocs, setDoc, doc } from 'firebase/firestore/lite'
import { getCourses, getEvents, getPlayers } from "../utils/manageData"

export default class Player {
    constructor(name, attributes, bio, handicap, stats)
    {
        this.name = name
        this.attributes = attributes
        this.bio = bio
        this.handicap = handicap
        this.stats = stats
        this.statThreshold = 14
        this.assignPointsAndWinnings()
        this.assignRecentEvent()
        this.assignOverall()
        this.assignPowerRank()
        this.assignBirdies()
    }

    // Assign points for a player
    assignPointsAndWinnings()
    {
        // Pull all events
        const events = getEvents()

        let tempPoints = 0
        let tempWinnings = 0
        
        for (const eventData of events)
        {
            let placement = 0

            if (eventData.eventType === "team") { placement = eventData.standings[eventData.teams[this.name]] }
            else { placement = eventData.standings[this.name] }

            if (placement !== undefined)
            {
                tempPoints += eventData.pointBreakdown[placement]
                tempWinnings += eventData.purseBreakdown[placement]
            }

        }

        this.points = tempPoints
        this.winnings = tempWinnings
    }

    // Assign most recent finish
    assignRecentEvent()
    {
        const events = getEvents()

        for (const event of events)
        {
            if (Object.keys(event.standings).includes(this.name))
            {
                this.recentEvent = event
                break
            }
        }
    }

    // Assign overall
    assignOverall()
    {
        const sumValues = obj => Object.values(obj).reduce((a, b) => a + b.value, 0)
        const overall = sumValues(this.stats) / 8
        this.overall = overall

        let prevOverallTotal = 0

        for (const stat of Object.keys(this.stats))
        {
            const today = new Date()
            const threshold = Date.parse(new Date(today.getFullYear(), today.getMonth(), today.getDate() - this.statThreshold))
    

            if (this.stats[stat].updated.seconds * 1000 > threshold) { prevOverallTotal += this.stats[stat].valuePrev }
            else { prevOverallTotal += this.stats[stat].value }
        }   

        this.overallPrev = prevOverallTotal / 8
    }

    getRank() {
        
        const pointList = []
        
        for (const p of getPlayers())
        {
            if (pointList.filter(x => x == p.points).length === 0) { pointList.push(p.points) }
        }

        pointList.sort().reverse()

        const rank = pointList.indexOf(this.points) + 1
        
        return rank
    }

    showStatChange(stat) {
        const today = new Date()
        const threshold = Date.parse(new Date(today.getFullYear(), today.getMonth(), today.getDate() - this.statThreshold))

        let ret = false

        if (this.stats[stat].updated.seconds * 1000 > threshold) { ret = true }

        if (this.stats[stat].value === this.stats[stat].valuePrev) { ret = false }

        return ret
    }

    assignPowerRank() {

        // Get events that have already happened
        const events = getEvents().filter((e) => e.date < new Date()).slice(0, 2)

        // Point portion of power index
        let playerPoints = 0
        let possiblePoints = 0

        // Initialize power index and index variables
        let pIndex = 0
        let i = 0
        
        // Iterate through events
        for (const e of events) {

            // Points the player scored in this event
            playerPoints = e.calcPoints(this.name)

            // Check if player has a score for the event
            if (playerPoints === undefined) { continue }

            // Max points possible for this event
            possiblePoints = e.pointBreakdown["1"]

            // Net score for the player in this event
            const net = e.calcNet(this.name)

            // Lowest net score by any player this event
            let lowScore = net
            for (const player of e.getParticipants())
            {
                let playerNet = e.calcNet(player)
                if (playerNet < lowScore) { lowScore = playerNet }
            }

            // Multiply the power index by 0.75 for team events
            let factor = 1
            if (e.eventType == "team") { factor = 0.75 }

            // Players stroke score for this event index
            let eventStrokeScore = ((lowScore - net + 10) * factor) / 10

            // Players points score for this event
            let eventPointScore = (playerPoints / possiblePoints) * factor

            // Assign weights based on recency of event and score typ
            if (i == 0) { eventStrokeScore *= 45; eventPointScore *= 15 }
            else { eventStrokeScore *= 33; eventPointScore *= 7 }

            // Add scores to power index
            pIndex += eventPointScore
            pIndex += eventStrokeScore

            // Iterate
            i++
        }

        // Assign power index to player
        this.powerIndex = ((pIndex)).toFixed(1)
    }

    assignBirdies() {

        // Get events
        const events = getEvents().filter((e) => e.eventType != "team")
        const courses = getCourses()

        // Initialize temporary variables
        let birdies = 0
        let pars = 0
        let bogeys = 0
        let doubleBogeys = 0
        let tripleBogeys = 0
        let quadBogeys = 0
        let pentaBogeys = 0

        // Loop through events
        for (const e of events) {
            const playerScorecard = e.scorecard[this.name]
            const courseScorecard = courses.find((c) => c.name = e.course).holes

            for (const hole of courseScorecard) {
                const playerScore = playerScorecard[hole.number - 1]
                const par = hole.par

                const score = playerScore - par

                if (score == -1) { birdies += 1 }
                else if (score == 0) { pars += 1 }
                else if (score == 1) { bogeys += 1 }
                else if (score == 2) { doubleBogeys += 1 }
                else if (score == 3) { tripleBogeys += 1 }
                else if (score == 4) { quadBogeys += 1 }
                else if (score == 5) { pentaBogeys += 1 }
            }
        }

        // Assign variables
        this.birdies = birdies
        this.pars = pars
        this.bogeys = bogeys
        this.doubleBogeys = doubleBogeys
        this.tripleBogeys = tripleBogeys
        this.quadBogeys = quadBogeys
        this.pentaBogeys = pentaBogeys
    }

}