import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { getCourses } from "../utils/manageData"

export default function Courses()
{
    // Navigation
    const navigate = useNavigate()
    const courses = getCourses()

    // Render courses
    function renderCourseList() {
        return (
            courses.map((course) =>
            {
                return (
                    
                    <div className="schedule-item" onClick={() => { navigate(`/course-profile?id=${course.name}`) }}> 
                        
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ color: "#111" }}>{course.name}</div>
                            <div style={{ color: "#999" }}>{course.location}</div>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>

                        </Box>

                    </div>
                )
            })
        )
    }

    return (
        <div className="container">

            <Box fontFamily={"Rye"} sx={{ width: "100%", textAlign: "center", padding: "1rem", fontSize: 28, backgroundColor: "#efefef", borderBottom: "1px solid #c4c4c4", position: "sticky", top: 0, zIndex: 3 }} onClick={() => {document.documentElement.scrollTo({top: 0})}}>
                Course List
            </Box>

            <Box sx={{ width: "100%", textAlign: "center", fontSize: "12px", backgroundColor: "#efefef", boxShadow: "0 2px 2px -2px #333;", display: "flex", height: "56px", alignItems: "center", marginBottom: "1.6rem" }}>
                <div className="navbar-item" style={{ padding: "18px 8px" }} ></div>
                <div className={`navbar-item`} onClick={() => {navigate("/")}}>Overview</div>
                <div className={`navbar-item`} onClick={() => {navigate("/schedule")}}>Schedule</div>
                <div className={`navbar-item navbar-item-selected`}>Courses</div>
                <div className={`navbar-item`} onClick={() => {navigate("/latest")}}>Latest</div>
            </Box>

            { renderCourseList() }

        </div>
    )
}