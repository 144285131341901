import { db } from "../pages/Firebase"
import { collection, getDoc, getDocs, setDoc, doc } from 'firebase/firestore/lite'

export default class News {
    constructor(title, body, date, players, events, courses)
    {
        this.title = title
        this.body = body
        this.date = new Date(date.seconds * 1000)
        this.players = players
        this.events = events
        this.courses = courses
    }
}