import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import GolfEvent from "../classes/GolfEvent"
import { useState } from "react"
import { db } from "./Firebase"
import { collection, getDoc, getDocs, setDoc, doc } from 'firebase/firestore/lite'
import { getEvents } from "../utils/manageData"

export default function Schedule()
{
    const navigate = useNavigate()
    
    const [eventList, setEventList] = useState(getEvents())

    return (
        <div className="container">

            <Box fontFamily={"Rye"} sx={{ width: "100%", textAlign: "center", padding: "1rem", fontSize: 28, backgroundColor: "#efefef", borderBottom: "1px solid #c4c4c4", position: "sticky", top: 0, zIndex: 3 }} onClick={() => {document.documentElement.scrollTo({top: 0})}}>
                Schedule
            </Box>

            <Box sx={{ width: "100%", textAlign: "center", fontSize: "12px", backgroundColor: "#efefef", boxShadow: "0 2px 2px -2px #333;", display: "flex", height: "56px", alignItems: "center", marginBottom: "0.5rem" }}>
                <div className="navbar-item" style={{ padding: "18px 8px" }} ></div>
                <div className={`navbar-item`} onClick={() => {navigate("/")}}>Overview</div>
                <div className={`navbar-item navbar-item-selected`}>Schedule</div>
                <div className={`navbar-item`} onClick={() => {navigate("/courses")}}>Courses</div>
                <div className={`navbar-item`} onClick={() => {navigate("/latest")}}>Latest</div>
            </Box>

            <div className="page-subheader">May, 2024</div>

            {eventList.map((event) =>
            {
                let tag = "Completed"
                let tagColor = "green"
                let additionalClass = "green"
                if (event.date > new Date()) { tag = "Upcoming"; tagColor = "#25d"; additionalClass = "blue" }

                if (event.date.getMonth() === 4) {
                    return (
                        <div className={`schedule-item ${additionalClass}`} onClick={() => navigate(`/event?id=${event.name}`, { state: { event: event } })}>
                            
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <div>{event.name}</div>
                                <div style={{ color: tagColor }}>{tag}</div>
                            </Box>
    
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <div>{event.date.toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"}) }</div>
                            </Box>
                        
                        </div>
                    )
                }
                else { return <></> }

                
            })}

            { eventList.length > 0 && <>

            <div className="page-subheader">April, 2024</div>

            {eventList.map((event) =>
            {
                let tag = "Completed"
                let tagColor = "green"
                let additionalClass = "green"
                if (event.date > new Date()) { tag = "Upcoming"; tagColor = "#25d"; additionalClass = "blue" }

                if (event.date.getMonth() === 3) {

                    return (
                        <div className={`schedule-item ${additionalClass}`} onClick={() => navigate(`/event?id=${event.name}`, { state: { event: event } })}>
                            
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <div>{event.name}</div>
                                <div style={{ color: tagColor }}>{tag}</div>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <div>{event.date.toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"}) }</div>
                            </Box>
                        
                        </div>
                    )
                }
                else { return <></> }

            })}
            
            </> }
            

        </div>
    )
}