import './style.css'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import PlayerProfile from './pages/PlayerProfile.jsx'
import Navbar from './Navbar.jsx'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import TourHistory from './pages/TourHistory.jsx'
import PlayerBios from './pages/PlayerBios.jsx'
import CoursePage from './pages/Course.jsx'
import Schedule from './pages/Schedule.jsx'
import EventPage from './pages/Event.jsx'
import Data from './pages/Data.jsx'
import PlayerPage from './pages/Player.jsx'
import Courses from './pages/Courses.jsx'
import Article from './pages/Article.jsx'
import Latest from './pages/Latest.jsx'
import { Analytics } from "@vercel/analytics/react"
import AttributesPage from './pages/Attributes.jsx'

const theme = createTheme({
    palette: {
        mode: "dark"
    }
})

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                {/* <Navbar /> */}
                <Data />
                <Analytics />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/profile' element={<PlayerProfile />} />
                    <Route path='/tour-history' element={<TourHistory />} />
                    <Route path='/player-bios' element={<PlayerBios />} />
                    <Route path='/course-profile' element={<CoursePage />} />
                    <Route path='/schedule' element={<Schedule />} />
                    <Route path='/event' element={<EventPage />} />
                    <Route path='/player' element={<PlayerPage />} />
                    <Route path='/courses' element={<Courses />} />
                    <Route path='/article' element={<Article />} />
                    <Route path='/latest' element={<Latest />} />
                    <Route path='/attributes' element={<AttributesPage />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </>

)