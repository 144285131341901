import { useNavigate } from 'react-router-dom'
import { Box } from "@mui/material"
import { useEffect, useState } from 'react'
import { getCourses, getEvents, getNews } from '../utils/manageData'
import { useURLID } from '../utils/useURLID'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function EventPage()
{
    // Location & navigation
    const navigate = useNavigate()
    const id = useURLID()

    // Event variables
    const [event, setEvent] = useState(getEvents().find((e) => e.name === id))
    const [course, setCourse] = useState(getCourses().find((c) => c.name === event.course))
    const [news, setNews] = useState(getNews())
    const [loading, setLoading] = useState(event === undefined)

    // Load data if necessary
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            // Check if location is available
            if (event === undefined)
            {
                setEvent(getEvents().find((e) => e.name === id))
                setCourse(getCourses().find((c) => c.name === getEvents().find((e) => e.name === id).course || ""))
                setNews(getNews())
                setLoading(false)
            }
        }, 1000)
        return () => { clearInterval(interval) }
    })

    let subHeader = "+/-"

    if (loading) { return <></>}

    return (
        <div className="container">
           
           <Box className="header">
                <ArrowBackIcon className="selectable" onClick={() => {navigate("/")}} />
                {event.name}
                <Box sx={{ width: "30px" }}></Box>
            </Box>

            <div className="page-subheader">Event Info</div>

            { course !== "" && 
            <table style={{ marginBottom: "0.4rem" }}>
                <thead>
                    <tr>
                        <th>Course</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='event-td selectable'  onClick={() => navigate(`/course-profile?id=${event.course}`)}>{event.course}</td>
                        <td className='event-td'>{event.date.toLocaleDateString()}</td>
                    </tr>
                </tbody>
            </table>

            }

            <table style={{ marginBottom: "0.2rem" }}>
                <thead>
                    <th>Purse</th>
                    <th>Format</th>
                </thead>
                <tbody>
                    <tr>
                        <td className='event-td'>{event.purse}</td>
                        <td className='event-td'>{event.format}</td>
                    </tr>
                </tbody>
            </table>

            <div className="page-subheader">Scorecard</div>

            {/* <div className="scorecard">
                {course.holes.map((hole) => 
            {
                console.log(hole)
                return (
                    <>
                    <div>{hole.par}</div>
                    <div>{hole.par}</div>
                    </>
                )
            })}
            </div> */}

            <div className="scorecard" style={{ marginBottom: "0.2rem" }}>
            {/* <div style={{ width: "90%", overflow: "auto", height: "50rem" }}>  */}
            <div style={{ overflowX: "auto" }}>
            <table style={{marginBottom: "0rem", overflowX: "auto", minWidth: "100%" }}>
                <thead>

                    { course !== "" && <>

                    <tr>
                        <th className='scorecard-box'>Hole</th>
                        {course.holes.map((hole) =>
                        {
                            if (hole.number === 9) {return (<><th className='scorecard-box'>{hole.number}</th><th className='scorecard-box'>IN</th></>)}
                            if (hole.number === 18) {return (<><th className='scorecard-box'>{hole.number}</th><th className='scorecard-box'>OUT</th></>)}
                            return ( <th className='scorecard-box'>{hole.number}</th>)
                        })}
                    </tr>
                    
                    
                    <tr>
                       <th className='scorecard-box'>Yds</th>

                        {course.holes.map((hole) =>
                        {
                            if (hole.number % 9 === 0) 
                            {
                                return (
                                    <>
                                        <th className='scorecard-box'>{hole.length}</th>
                                        <th className='scorecard-box'>{course.holes.slice(0, hole.number).reduce((acc, curr) => acc + curr.length, 0)}</th>
                                    </>
                            )}

                            return ( <th className='scorecard-box'>{hole.length}</th>)
                        })}
                    </tr>

                    <tr style={{ borderBottom: "1px solid #888" }}>
                       <th className='scorecard-box' style={{ borderBottom: "1px solid #888" }}>Par</th>

                        {course.holes.map((hole) =>
                        {
                            if (hole.number % 9 === 0) 
                            {
                                return (
                                    <>
                                        <th className='scorecard-box' style={{ borderBottom: "1px solid #888" }}>{hole.par}</th>
                                        <th className='scorecard-box' style={{ borderBottom: "1px solid #888" }}>{course.holes.slice(0, hole.number).reduce((acc, curr) => acc + curr.par, 0)}</th>
                                    </>
                            )}
                            return ( <th className='scorecard-box' style={{ borderBottom: "1px solid #888" }}>{hole.par}</th>)
                        })}
                    </tr>
                    </>}

                    
                    {event.scorecard !== undefined ?
                    <>
                

                    {event.getArrayOfSortedPlayers().map((player) =>
                    {
                        return (
                        <>
                        <tr>
                        <th className='scorecard-box scorecard-player'>{player}</th>
                        {event.scorecard[player].map((score, index) =>
                        {   

                            let bgcolor = ""

                            let total = event.scorecard[player].slice(0, index + 1).reduce((acc, curr) => acc + curr, 0)
                            if (total == 0) { total = "" }
                            
                            if (score == course.holes[index].par - 1) { bgcolor = "#00ff0044" }
                            else if (score == course.holes[index].par - 2) { bgcolor = "#ffff0055" }
                            else if (score == course.holes[index].par + 1) { bgcolor = "#ff000022" }
                            else if (score == course.holes[index].par + 2) { bgcolor = "#ff000033" }
                            else if (score == course.holes[index].par + 3) { bgcolor = "#ff000044" }
                            else if (score == course.holes[index].par + 4) { bgcolor = "#ff000055" }
                            else if (score > course.holes[index].par + 4) { bgcolor = "#ff000077" }

                            if (index === 8 || index === 17) 
                            {
                                return (
                                    <>
                                        <th className='scorecard-box scorecard-player' style={{ backgroundColor: bgcolor }} >{score}</th>
                                        <th className='scorecard-box' style={{ color: "#000" }} >{total}</th>
                                    </>
                            )}
                            return (<th className='scorecard-box scorecard-player' style={{ backgroundColor: bgcolor }} >{score}</th>)
                        })}
                        
                        </tr>


                        {/* <tr style={{ borderTop: "1px solid black" }}>
                        <th className='scorecard-box scorecard-player scorecard-box-pts' style={{ backgroundColor: "#efefef" }}>{subHeader}</th>
                        {event.scorecard[player].map((score, index) =>
                        {   

                            let bgcolor = "#efefef"

                            let plusminus = event.scorecard[player].slice(0, index + 1).reduce((acc, curr) => acc + curr, 0) - course.holes.slice(0, index + 1).reduce((acc, curr) => acc + curr.par, 0)
                            if (event.scorecard[player].slice(0, index + 1).reduce((acc, curr) => acc + curr, 0) == 0) { plusminus = "" }

                            if (index === 8 || index === 17) 
                            {
                                return (
                                    <>
                                        <th className='scorecard-box scorecard-player scorecard-box-pts' style={{ backgroundColor: bgcolor }} >{plusminus}</th>
                                        <th className='scorecard-box scorecard-box-pts'>{plusminus}</th>
                                    </>
                            )}
                            return (<th className='scorecard-box scorecard-player  scorecard-box-pts' style={{ backgroundColor: bgcolor }} >{plusminus}</th>)
                        })}

                        </tr> */}
                        </>
                        )

                    })}

                    </> : <></>}

                    {course !== "" && <>

                    <tr>
                       <th className='scorecard-box'>Handicap</th>

                        {course.holes.map((hole) =>
                        {
                            if (hole.number % 9 === 0) {return (<><th className='scorecard-box'>{hole.handicap}</th><th className='scorecard-box'></th></>)}
                            return ( <th className='scorecard-box'>{hole.handicap}</th>)
                        })}
                    </tr>
                    </>}
                
                </thead>
            </table>
            </div>
            </div>
            {/* </div> */}

            <div className="page-subheader">Leaderboard</div>

            { event.eventType !== "team" && 
            <table  style={{ marginBottom: "0.2rem" }}>
                <thead>
                    <tr>
                        <th></th>
                        <th>NAME</th>
                        <th>GROSS</th>
                        <th>NET</th>
                        <th>PTS</th>
                    </tr>

                    {Object.keys(event.pointBreakdown).map((placement, index) =>
                    {

                        const players = []

                        for (const p of Object.keys(event.standings)) { if (event.standings[p] == placement) { players.push(p) } }

              
                        return (
                        players.map((player) => {

                            const gross = event.calcGross(player)
                            const net = event.calcNet(player)

                            return (
                                <tr className='selectable' onClick={() => navigate(`/player?id=${player}`)}>
                                    <td className='leaderboard-box' style={{ width: "16%" }}>{placement}</td>
                                    <td className='leaderboard-box' style={{ width: "20%" }}><em>{player}</em></td>
                                    <td className='leaderboard-box' style={{ width: "20%" }}><em>{gross}</em></td>
                                    <td className='leaderboard-box' style={{ width: "20%" }}><em>{net}</em></td>
                                    <td className='leaderboard-box' style={{ width: "20%" }}>{event.pointBreakdown[placement]}</td>
                                </tr>
                            )
                        }))

                        
                    })}

                

                </thead>
            </table>
            }

            { event.eventType === "team" && 
            <table style={{ marginBottom: "0.2rem" }}>
                <thead>
                    <tr>
                        <th>PLACEMENT</th>
                        <th>NAME</th>
                        <th>GROSS</th>
                        <th>PTS</th>
                    </tr>


                    <tr>
                        <td className='leaderboard-box' style={{ width: "20%" }}>1</td>
                        <td  className='leaderboard-box' style={{ width: "20%" }}><em>Team 1</em></td>
                        <td className='leaderboard-box' style={{ width: "20%" }}><em>67</em></td>
                        <td className='leaderboard-box' style={{ width: "20%" }}>31</td>
                    </tr>

                    <tr>
                        <td className='leaderboard-box' style={{ width: "20%" }}>2</td>
                        <td className='leaderboard-box' style={{ width: "20%" }}><em>Team 2</em></td>
                        <td className='leaderboard-box' style={{ width: "20%" }}><em>70</em></td>
                        <td className='leaderboard-box' style={{ width: "20%" }}>9</td>
                    </tr>
                </thead>
            </table>
            }

        <div className="page-subheader">News</div>

        { news.map((article) => 
            {
                if (article.events.includes(event.name))
                {
                    return (
                        <div className="news-card" onClick={() => { navigate(`/article?id=${article.title}`) }}>
                        <div>{article.title}</div>
                        <div className='news-card-date'>{article.date.toLocaleDateString()}</div>
                    </div>
                    )
                }
                return ( <></> )

            }) }

            <div style={{ marginTop: "2rem" }}></div>
            
        </div>
    )
}