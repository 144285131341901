import { db } from "./Firebase"
import { collection, getDoc, getDocs, setDoc, doc } from 'firebase/firestore/lite'
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { useState, useMemo } from "react"
import { Box, Typography } from "@mui/material"

const TourHistory = () =>
{

    const [waiting, setWaiting] = useState(true)
    const [data, setData] = useState([])

    async function getEvents() {
        const snapShot = await getDocs(collection(db, 'events'))
        snapShot.forEach((doc) =>
        {
            setWaiting(false)
            console.log(doc.data())
            setData([doc.data()])
            return doc.data()
        })
        console.log(data)
    }

    if (waiting)
        getEvents()

    const columns = useMemo(
    () => [
        {
            accessorKey: 'date', //hey a simple column for once
            header: 'Date',
            size: 80,
            Cell: ({ cell }) => cell.getValue().toDate().toDateString().substring(0, cell.getValue().toDate().toDateString().length - 5), //render Date as a string
        },
        {
            accessorKey: 'course', //hey a simple column for once
            header: 'Course',
            size: 80,
        },
    ],
    [],
    )
        
    const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { density: 'comfortable', columnVisibility: { expand: false } },
        enablePagination: false,
        enableBottomToolbar: false,
        renderDetailPanel: ({ row }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '10px'
              }}
            >
              <Box sx={{ textAlign: 'left', display: 'flex', flexDirection: 'column', height: 100, justifyContent: 'space-between' }}>
                <em style={{ width: 100, display: 'flex', justifyContent: 'space-between' }}>
                    <div> Format </div>
                    <div>-</div>
                    <div> {row.original.format} </div>
                </em>
                <em style={{ width: 100, display: 'flex', justifyContent: 'space-between' }}>
                    <div> Purse </div>
                    <div>-</div>
                    <div> {row.original.purse} </div>
                </em>
                <div style={{ width: 100, display: 'flex', justifyContent: 'space-between' }}>
                    <em> Winner </em>
                    <em>-</em>
                    <em> {row.original.standings[1]} </em>
                </div>
              </Box>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <strong>Point Breakdown</strong>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 1st </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[1]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 2nd </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[2]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 3rd </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[3]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 4th </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[4]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 5th </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[5]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 6th </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[6]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 7th </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[7]} </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div> 8th </div>
                    <div>-</div>
                    <div> {row.original.point_breakdown[8]} </div>
                </div>

              </Box>
            </Box>
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        padding: '10px'
                    }}
                    >
                        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                <strong style={{ marginBottom: '1rem' }}>Team 1</strong>
                                <div>Matt</div>
                                <div>Christian</div>
                                <div>Koose</div>
                                <div>Mikey</div>
                        </Box>
                        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                            <strong style={{ marginBottom: '1rem' }}>Team 2</strong>
                            <div>John</div>
                            <div>Ty</div>
                            <div>Jake</div>
                            <div>Sahil</div>
                        </Box>
                    </Box>
            </Box>
          ),
    })
    
    if (waiting)
        return <></>

    return(
        <div className="main" style={{ overflow: "scroll" }}>
        <div style={{ width: "100%" }}><MaterialReactTable table={table} /></div>
            
        <div style={{ height: '300px' }}> </div>
        <div style={{ height: '300px' }}> </div>
        </div>
    )
}

export default TourHistory