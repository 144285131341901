// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithRedirect, signOut, getRedirectResult } from "firebase/auth"
import { getFirestore } from "firebase/firestore/lite"
import { useEffect } from "react"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: import.meta.env.VITE_API_KEY,
    authDomain: import.meta.env.VITE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_ID,
    measurementId: import.meta.env.VITE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)

const provider = new GoogleAuthProvider()

export const signInWithGoogle = async () =>
{
    await signInWithRedirect(auth, provider)
    const name = await getRedirectResult(auth)
        .then((result) =>
        {
            const name = result.user.displayName
            const email = result.user.email
            const profilePic = result.user.photoURL
        
            localStorage.setItem("name", name)
            localStorage.setItem("email", email)
            localStorage.setItem("profilePic", profilePic)
        
            return name
        })
        .catch((error) =>
        {
            console.log(error)
        })
    return name
    
}

const result = async () =>
{
    const name = await getRedirectResult(auth)
        .then((result) =>
        {
            console.log(result)
            const name = result.user.displayName
            const email = result.user.email
            const profilePic = result.user.photoURL
        
            localStorage.setItem("name", name)
            localStorage.setItem("email", email)
            localStorage.setItem("profilePic", profilePic)
        
            return name
        })
        .catch((error) =>
        {
            console.log(error)
        })
    return name
}

// result()


export const signOutWithGoogle = () =>
{
    signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
}