import Course from "../classes/Course.js"
import GolfEvent from "../classes/GolfEvent.js"
import News from "../classes/News.js"
import Player from "../classes/Player.js"
import { db } from "../pages/Firebase.jsx"
import { collection, getDoc, getDocs, setDoc, doc } from 'firebase/firestore/lite'
import { uploadCourse } from "./uploadCourse.js"
import { uploadEvent } from "./uploadEvent.js"

// Loads and writes all data to session storage
export async function getAllData()
{
    await loadPlayers()
    await loadEvents()
    await loadCourses()
    await loadNews()

    /** UNCOMMENT TO UPLOAD DATA TO THE DATABASE */
    // await setScorecard()
    // await uploadCourse()
    // await uploadEvent()
}


// Function to load players from database
async function loadPlayers() {
    
    // Get snapshot of all players
    const snapShot = await getDocs(collection(db, 'players'))

    // Create player list
    const playerList = []
    
    // Set player list
    snapShot.forEach(async (player) =>
    {
        const playerData = player.data()
        const playerObject = new Player(playerData.name, playerData.attributes, playerData.bio, playerData.handicap, playerData.stats)
        playerList.push(playerObject)
    })

    // Assign player points
    for (const player of playerList) { await player.assignPointsAndWinnings() }
    
    // Sort player list
    playerList.sort((a, b) => a.points < b.points ? 1 : -1)

    // Set session storage
    sessionStorage.setItem("players", JSON.stringify(playerList))
}


// Function to load events from database
async function loadEvents() {
  
    // Get snapshot of all events
    const snapShot = await getDocs(collection(db, 'events'))

    // Create event list
    const eventList = []
    
    // Set event list
    snapShot.forEach((event) =>
    {
        const eventData = event.data()
        // const eventObject = new GolfEvent(eventData.name, eventData.course, eventData.date, eventData.eventType, eventData.format, eventData.pointBreakdown, eventData.purse, eventData.purseBreakdown, eventData.standings, eventData.scorecard)
        eventList.push(eventData)
    })  

    // Sort event list by date
    // eventList.sort((a, b) => a.date < b.date ? 1 : -1)
    
    // Set session storage
    sessionStorage.setItem("events", JSON.stringify(eventList))
}


// Function to load courses from database
async function loadCourses() {
        
    // Get snapshot of all courses
    const snapShot = await getDocs(collection(db, 'courses'))

    // Create course list
    const courseList = []

    // Set course list
    snapShot.forEach((course) =>
    {

        // Set course data
        const courseData = course.data()
        const courseObject = new Course(courseData.name, courseData.par, courseData.location, courseData.length, courseData.holes, courseData.rating)
        courseList.push(courseObject)
    })  

    // Sort course list by name
    courseList.sort((a, b) => a.name < b.name ? 1 : -1)
        
    // Set session storage
    sessionStorage.setItem("courses", JSON.stringify(courseList))
}


// Function to load news from database
async function loadNews() {
        
    // Get snapshot of all news
    const snapShot = await getDocs(collection(db, 'news'))

    // Create news list
    const newsList = []

    // Set news list
    snapShot.forEach((article) =>
    {

        // Set news data
        const newsData = article.data()
        // const newsObject = new News(newsData.title, newsData.body, newsData.date, newsData.players, newsData.events, newsData.courses)
        newsList.push(newsData)
    })  

    // Sort news list by name
    // newsList.sort((a, b) => a.name < b.name ? 1 : -1)
        
    // Set session storage
    sessionStorage.setItem("news", JSON.stringify(newsList))
}


// Function to get players from sessionStorage
export function getPlayers() {

    // Get item from session storage
    const tempPlayerList = JSON.parse(sessionStorage.getItem("players") || "[]")

    // Create return list
    const playerList = []

    // If there are no players, then reload data from database
    if (tempPlayerList.length < 1) { return [] }
    else
    {
        for (const playerData of tempPlayerList)
        {
            const player = new Player(playerData.name, playerData.attributes, playerData.bio, playerData.handicap, playerData.stats)
            playerList.push(player)
        }
    }
    
    // Sort player list
    playerList.sort((a, b) => a.points < b.points ? 1 : -1)

    return playerList
}


// Function to get events from sessionStorage
export function getEvents() {

    // Get item from session storage
    const tempEventList = JSON.parse(sessionStorage.getItem("events") || "[]")

    // Create return list
    const eventList = []

    // If there are no players, then reload data from database
    if (tempEventList.length < 1) { return [] }
    else
    {
        for (const eventData of tempEventList)
        {
            const eventObject = new GolfEvent(eventData.name, eventData.course, eventData.date, eventData.eventType, eventData.format, eventData.pointBreakdown, eventData.purse, eventData.purseBreakdown, eventData.standings, eventData.scorecard, eventData.handicaps, eventData.teams)
            eventList.push(eventObject)
        }
    }
    
    // Sort player list
    eventList.sort((a, b) => a.date < b.date ? 1 : -1)

    return eventList
}


// Function to get courses from sessionStorage
export function getCourses() {

    // Get item from session storage
    const tempCourseList = JSON.parse(sessionStorage.getItem("courses") || "[]")

    // Create return list
    const courseList = []

    // If there are no courses, then return empty array
    if (tempCourseList.length < 1) { return [] }
    else
    {
        for (const courseData of tempCourseList)
        {
            const courseObject = new Course(courseData.name, courseData.par, courseData.location, courseData.length, courseData.holes, courseData.rating)
            courseList.push(courseObject)
        }
    }

    return courseList
}

// Function to get news from sessionStorage
export function getNews() {

    // Get item from session storage
    const tempNewsList = JSON.parse(sessionStorage.getItem("news") || "[]")

    // Create return list
    const newsList = []

    // If there are no courses, then return empty array
    if (tempNewsList.length < 1) { return [] }
    else
    {
        for (const newsData of tempNewsList)
        {
            const newsObject = new News(newsData.title, newsData.body, newsData.date, newsData.players, newsData.events, newsData.courses)
            newsList.push(newsObject)
        }
    }
    
    // Sort news list
    newsList.sort((a, b) => a.date < b.date ? 1 : -1)

    return newsList
}


// Function set scorecard
export async function setScorecard() {
    const eventName = "Heron Glen Classic"
    

    // Get snapshot of all events
    const snapShot = await getDocs(collection(db, 'events'))

    // Create event list
    const eventList = []
    
    // Set event list
    snapShot.forEach((event) =>
    {
        const eventData = event.data()
        if (eventData.name === eventName) {

            eventData.scorecard['Matt'] =       [5,6,5,5,4,5,4,5,4,6,7,6,7,3,4,6,4,7]
            eventData.scorecard['Jake'] =       [6,7,6,6,4,6,4,6,4,6,6,4,10,5,5,6,3,6]
            eventData.scorecard['Ty'] =         [7,7,5,4,4,4,4,6,4,4,5,6,7,5,2,5,5,4]
            eventData.scorecard['Christian'] =  [7,7,5,5,2,6,4,9,5,7,5,5,8,5,5,7,5,7]
            eventData.scorecard['Sahil'] =      [8,8,8,6,3,7,5,6,5,8,8,6,7,5,4,7,5,5]
            eventData.scorecard['John'] =       [4,6,5,6,4,6,5,6,4,6,4,4,6,4,4,6,3,5]


            // setDoc(doc(collection(db, 'events'), "2"), eventData )
        }
    })  
}
