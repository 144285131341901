export default class GolfEvent {
    constructor(name, course, date, eventType, format, pointBreakdown, purse, purseBreakdown, standings, scorecard, handicaps, teams)
    {
        this.name = name
        this.course = course
        this.date = new Date(date.seconds * 1000)
        this.eventType = eventType
        this.format = format
        this.pointBreakdown = pointBreakdown
        this.purse = purse
        this.purseBreakdown = purseBreakdown
        this.standings = standings
        this.scorecard = scorecard
        this.handicaps = handicaps
        this.teams = teams
    }

    calcGross(player) {
        let total = 0

        if (this.eventType === "team")
        {
            total = this.scorecard[this.teams[player]].reduce((partialSum, a) => partialSum + a, 0)
        }
        else
        {
            total = this.scorecard[player].reduce((partialSum, a) => partialSum + a, 0)
        }
        
        return total
    }

    calcNet(player) {
        let total = 0

        if (this.eventType === "team")
        {
            total = this.scorecard[this.teams[player]].reduce((partialSum, a) => partialSum + a, 0) - this.handicaps[this.teams[player]]
        }
        else
        {
            total = this.scorecard[player].reduce((partialSum, a) => partialSum + a, 0) - this.handicaps[player]
        }
        
        return total
    }

    getArrayOfSortedPlayers() {

        let retArray = []
        let tempArray = []

        for (const player of Object.keys(this.scorecard)) {
            const gross = this.calcGross(player)
            tempArray.push({ "player": player, "gross": gross })
        }

        // Sort temp array by gross score
        tempArray.sort((a, b) => a.gross - b.gross)

        for (const obj of tempArray) {
            retArray.push(obj.player)
        }

        return retArray
    }

    calcPoints(player) {

        let points = 0 

        if (this.eventType === "team")
        {
            points = this.pointBreakdown[this.standings[this.teams[player]]]
        }
        else
        {
            points = this.pointBreakdown[this.standings[player]]
        }

        return points

    }

    getParticipants() {
        let retArray = []

        if (this.eventType === "team") { retArray = Object.keys(this.teams) }
        else { retArray = Object.keys(this.standings) }

        return retArray
    }
}