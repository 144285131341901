import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { getEvents, getNews, getPlayers } from '../utils/manageData'
import { useURLID } from '../utils/useURLID'
import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function PlayerPage()
{

    // Location & navigation
    const navigate = useNavigate()
    const id = useURLID()

    // Event variable
    const [player, setPlayer] = useState(getPlayers().find((p) => p.name === id))
    const [news, setNews] = useState(getNews())
    const [loading, setLoading] = useState(player === undefined)
    const [events, setEvents] = useState(getEvents())

    // Load data if necessary
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            // Check if location is available
            if (player === undefined)
            {
                setPlayer(getPlayers().find((p) => p.name === id))
                setEvents(getEvents())
                setNews(getNews())
                setLoading(false)
            }
        }, 1000)
        return () => { clearInterval(interval) }
    })

    // Render stat
    function renderStat(stat)
    {

        return (
            <>
            <th style={{ textTransform: "capitalize" }}>
                <div className="" >{stat}</div>
                {/* {stat} */}
                
            </th>
            <td style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}>
                <div className='player-rating'>
                {/* {player.showStatChange(stat) === true &&
                    <div style={{ display: "flex", border: "0px solid blue", alignItems: "center", zIndex: "-2" }}>
                        {(player.stats[stat].value - player.stats[stat].valuePrev) < 0 ?
                            <div className="arrow-down"></div> : <div className="arrow-up"></div>
                        }
                        {(player.stats[stat].value - player.stats[stat].valuePrev) < 0 ?
                            <div style={{ color: "#d00" }}>{Math.abs(player.stats[stat].value - player.stats[stat].valuePrev)}</div> : <div style={{ color: "#0d0" }}>{Math.abs(player.stats[stat].value - player.stats[stat].valuePrev)}</div>
                        }
                        
                    </div>
                    } */}
                    {player.stats[stat].value} 

                    {player.showStatChange(stat) === true &&
                    <div style={{ display: "flex", border: "0px solid blue", alignItems: "center" }}>
                        {(player.stats[stat].value - player.stats[stat].valuePrev) < 0 ?
                            <div className="arrow-down"></div> : <div className="arrow-up"></div>
                        }
                        {(player.stats[stat].value - player.stats[stat].valuePrev) < 0 ?
                            <div className='rating-change' style={{ color: "#d00" }}>{Math.abs(player.stats[stat].value - player.stats[stat].valuePrev)}</div> : <div className='rating-change' style={{ color: "#0d0" }}>{Math.abs(player.stats[stat].value - player.stats[stat].valuePrev)}</div>
                        }
                        
                    </div>
                    }
                </div>
            </td>
            </>
        )
    }

    function renderStatsTable() {

        const bogeyUnderPercentage = (player.birdies + player.pars + player.bogeys) / (player.birdies + player.pars + player.bogeys + player.doubleBogeys + player.tripleBogeys + player.quadBogeys + player.pentaBogeys)
        let topThrees = 0
        let bestRound = 200

        for (const e of events) {
            if (e.standings[player.name] < 4) { topThrees += 1 }
            console.log(e.calcGross([player.name]))
            if (e.calcGross([player.name]) < bestRound && e.eventType !== "team" && e.calcGross([player.name]) > 0) { bestRound = e.calcGross([player.name]) }
        }
        return (
            <table>
                <thead>
                    <tr>
                        <th>BGY- %</th>
                        <th>TOP 3</th>
                        <th>BEST RND</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{(bogeyUnderPercentage * 100).toFixed(1)}%</td>
                        <td>{topThrees}</td>
                        <td>{bestRound}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    if (loading) return (<></>)

    return (
        <div className="container">
            
            <Box className="header">
                <ArrowBackIcon className="selectable" onClick={() => {navigate("/")}} />
                {player.name}
                <Box sx={{ width: "30px" }}></Box>
            </Box>

            <div className="page-subheader">Info</div>

            <table>
                <tbody>
                    <tr>
                        <th>NAME</th>
                        <td>{player.name}</td>
                    </tr>
                    {/* <tr>
                        <th>AGE</th>
                        <td>{player.age}</td>
                    </tr> */}
                    <tr>
                        <th>PTS</th>
                        <td>{player.points}</td>
                    </tr>
                    <tr>
                        <th>HDCP</th>
                        <td>{player.handicap}</td>
                    </tr>
                    
                    <tr>
                        <th>RNK</th>
                        <td>{player.getRank()}</td>
                    </tr>
                    <tr>
                        <th>WNGS</th>
                        <td>${player.winnings}</td>
                    </tr>
                </tbody>
            </table>

            <div className="page-subheader">Bio</div>

            <table>
                <tbody>
                    <tr>
                        <td style={{ textAlign: "left" }}>
                            {player.bio}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="page-subheader">Rating</div>

            <table style={{ marginBottom: 6 }}>
                <tbody>
                    <tr style={{ height: "2rem" }}>
                        <th>
                            Overall
                        </th>
                        <td  style={{ height: "2rem" }}>
                            <div className='player-rating' style={{ justifyContent: "center", }}>
                            {player.overall !== player.overallPrev &&
                                    <div style={{ display: "flex", border: "0px solid blue", alignItems: "center", zIndex: "-3" }}>
                                        {(player.overall - player.overallPrev) < 0 ?
                                            <div className="arrow-down"></div> : <div className="arrow-up"></div>
                                        }
                                        {(player.overall - player.overallPrev) < 0 ?
                                            <div style={{ color: "#d00" }}>{Math.round(Math.abs(player.overall - player.overallPrev))}</div> : <div style={{ color: "#0d0" }}>{Math.round(Math.abs(player.overall - player.overallPrev))}</div>
                                        }
                                        
                                    </div>
                                }
                                {Math.round(player.overall)}
                                {player.overall !== player.overallPrev &&
                                    <div style={{ display: "flex", border: "0px solid blue", alignItems: "center", marginLeft: "1rem" }}>
                                        {(player.overall - player.overallPrev) < 0 ?
                                            <div className="arrow-down"></div> : <div className="arrow-up"></div>
                                        }
                                        {(player.overall - player.overallPrev) < 0 ?
                                            <div style={{ color: "#d00" }}>{Math.round(Math.abs(player.overall - player.overallPrev))}</div> : <div style={{ color: "#0d0" }}>{Math.round(Math.abs(player.overall - player.overallPrev))}</div>
                                        }
                                        
                                    </div>
                                }
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        {renderStat("distance")}
                        {renderStat("accuracy")}
                    </tr>
                    <tr>
                        {renderStat("ballStriking")}
                        {renderStat("putting")}
                    </tr>
                    <tr>
                        {renderStat("consistency")}
                        {renderStat("chipping")}
                    </tr>
                    <tr>
                        {renderStat("clutch")}
                        {renderStat("swag")}
                    </tr>
                </tbody>
            </table>

            <div className="page-subheader">Recent Results</div>

            <table>
                <thead>
                    <tr>
                        <th>EVENT</th>
                        <th>FINISH</th>
                        <th>PTS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr  className="selectable" onClick={() => {navigate(`/event?id=${player.recentEvent.name}`)}}>
                        <td>{player.recentEvent.name}</td>
                        <td>{player.recentEvent.standings[player.name]}</td>
                        <td>{player.recentEvent.pointBreakdown[player.recentEvent.standings[player.name]]}</td>
                    </tr>
                </tbody>
            </table>

            <div className="page-subheader">Tour Stats</div>
            
            { renderStatsTable() }

            <div className="page-subheader">News</div>

            { news.map((article) => 
            {
                if (article.players.includes(player.name))
                {
                    return (
                        <div className="news-card" onClick={() => {navigate(`/article?id=${article.title}`)}}>
                            {article.title}
                        </div>
                    )
                }
                return ( <></> )

            }) }

            <div style={{ marginTop: "2rem" }}></div>

        </div>
    )
}