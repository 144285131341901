import { db } from "./Firebase"
import { collection, getDoc, getDocs, setDoc, doc } from 'firebase/firestore/lite'
import { useState } from "react"
import { useLocation } from 'react-router-dom'

export default function PlayerProfile()
{
    const location = useLocation()

    const [waiting, setWaiting] = useState(true)
    const [player, setPlayer] = useState({
        name: "Koose",
        attributes:
        {
            power: 2.4,
            approach: 4.1,
            shortGame: 1,
            putting: 3
        },
        wins: 0,
        handicap: 100,
        powerRanking: 8,
        tourRanking: 8,
        points: 3.5
    })

    async function getPlayers() {
        const snapShot = await getDocs(collection(db, 'players'))
        snapShot.forEach((doca) =>
        {
            if (doca.data().name === location.state.player)
            {
                setWaiting(false)
                console.log(doca.data())
                setPlayer(doca.data())
                
                // console.log("Setting course")
                
                
                // setDoc(doc(collection(db, 'courses'), "BunkerHill"), 
                // {
                //     name: "Bunker Hill",
                //     par: 72,
                //     location: "Princeton, NJ",
                //     length: 5833,
                //     rating: 68.2,
                //     tees: "Back",
                //     holes:
                //     [
                //         { number: 1, par: 4, length: 368, handicap: 7 },
                //         { number: 2, par: 4, length: 288, handicap: 15 },
                //         { number: 3, par: 5, length: 489, handicap: 1 },
                //         { number: 4, par: 3, length: 149, handicap: 17 },
                //         { number: 5, par: 4, length: 312, handicap: 11 },
                //         { number: 6, par: 3, length: 185, handicap: 5 },
                //         { number: 7, par: 5, length: 491, handicap: 9 },
                //         { number: 8, par: 4, length: 327, handicap: 13 },
                //         { number: 9, par: 4, length: 340, handicap: 3 },
                //         { number: 10, par: 4, length: 335, handicap: 4 },
                //         { number: 11, par: 4, length: 281, handicap: 16 },
                //         { number: 12, par: 5, length: 475, handicap: 8 },
                //         { number: 13, par: 3, length: 182, handicap: 6 },
                //         { number: 14, par: 4, length: 362, handicap: 10 },
                //         { number: 15, par: 3, length: 148, handicap: 18 },
                //         { number: 16, par: 4, length: 344, handicap: 12 },
                //         { number: 17, par: 4, length: 285, handicap: 14 },
                //         { number: 18, par: 5, length: 472, handicap: 2 },
                //     ]

                // })

                // setDoc(doc(collection(db, 'players'), "johnny"), 
                // {
                //     name: "John",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 2,
                //     handicap: 30,
                //     powerRanking: 1,
                //     tourRanking: 1,
                //     points: 23.5
                // })

                // setDoc(doc(collection(db, 'players'), "jake"), 
                // {
                //     name: "Jake",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 0.5,
                //     handicap: 30,
                //     powerRanking: 2,
                //     tourRanking: 2,
                //     points: 18.5
                // })

                // setDoc(doc(collection(db, 'players'), "matt"), 
                // {
                //     name: "Matt",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 0.5,
                //     handicap: 30,
                //     powerRanking: 3,
                //     tourRanking: 3,
                //     points: 18.5
                // })

                // setDoc(doc(collection(db, 'players'), "sahil"), 
                // {
                //     name: "Sahil",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 1,
                //     handicap: 30,
                //     powerRanking: 4,
                //     tourRanking: 4,
                //     points: 14.5
                // })

                // setDoc(doc(collection(db, 'players'), "mikey"), 
                // {
                //     name: "Mikey",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 0.5,
                //     handicap: 30,
                //     powerRanking: 5,
                //     tourRanking: 5,
                //     points: 12.5
                // })

                // setDoc(doc(collection(db, 'players'), "hank"), 
                // {
                //     name: "Hank",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 0,
                //     handicap: 30,
                //     powerRanking: 6,
                //     tourRanking: 6,
                //     points: 9.5
                // })

                // setDoc(doc(collection(db, 'players'), "christian"), 
                // {
                //     name: "Christian",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 0,
                //     handicap: 30,
                //     powerRanking: 7,
                //     tourRanking: 7,
                //     points: 7.5
                // })

                // setDoc(doc(collection(db, 'players'), "mikehofbeck787"), 
                // {
                //     name: "Koose",
                //     attributes:
                //     {
                //         power: 5,
                //         approach: 5,
                //         shortGame: 5,
                //         putting: 5
                //     },
                //     wins: 0,
                //     handicap: 30,
                //     powerRanking: 8,
                //     tourRanking: 8,
                //     points: 3.5
                // })

                return doca.data()
            }

        })
    }

    if (waiting)
        getPlayers()

    if (waiting)
    {
        return(
            <div>
            </div>
        )
    }
    return(
        <div style={{ fontFamily: "Monaco, monospace", fontWeight: 400, height: "100vh", width: "100vw", display: "flex", flexDirection: "column" , color: "white", }}>
            
            <div style={{ fontFamily: "ESP_Bold_Italic, sans-serif", justifyContent: "center", width: "100vw", display: "flex", marginTop: '2vh', fontSize: 24, color: "white" }}>{player.name}</div>

            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "4vh" }}>
                <div>Tour Rank: {player.tourRanking}</div>
                <div>Tour Points: {player.points}</div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1vh" }}>
                <div>Handicap: {player.handicap}</div>
                <div>Outing Wins: {player.wins}</div>
            </div>

            <div style={{ borderBottom: "1px solid white", marginTop: "4vh", width: "80vw", marginLeft: "10vw" }} />

            {/* <div style={{ fontFamily: "ESP_Bold_Italic, sans-serif", justifyContent: "center", width: "100vw", display: "flex", marginTop: '6vh', fontSize: 16, color: "white" }}>Power - {player.attributes.power}</div>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1vh" }}>
                <div className="progress-bar-container">
                    <div className="progress-bar-indicator" style={{width: `${player.attributes.power * 10}%`}}></div>
                </div>
            </div>
            
            <div style={{ fontFamily: "ESP_Bold_Italic, sans-serif", justifyContent: "center", width: "100vw", display: "flex", marginTop: '6vh', fontSize: 16, color: "white" }}>Approach - {player.attributes.approach}</div>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1vh" }}>
                <div className="progress-bar-container">
                    <div className="progress-bar-indicator" style={{width: `${player.attributes.approach * 10}%`}}></div>
                </div>
            </div>

            <div style={{ fontFamily: "ESP_Bold_Italic, sans-serif", justifyContent: "center", width: "100vw", display: "flex", marginTop: '6vh', fontSize: 16, color: "white" }}>Short Game - {player.attributes.shortGame}</div>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1vh" }}>
                <div className="progress-bar-container">
                    <div className="progress-bar-indicator" style={{width: `${player.attributes.shortGame * 10}%`}}></div>
                </div>
            </div>

            <div style={{ fontFamily: "ESP_Bold_Italic, sans-serif", justifyContent: "center", width: "100vw", display: "flex", marginTop: '6vh', fontSize: 16, color: "white" }}>Putting - {player.attributes.putting}</div>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1vh" }}>
                <div className="progress-bar-container">
                    <div className="progress-bar-indicator" style={{width: `${player.attributes.putting * 10}%`}}></div>
                </div>
            </div> */}

        </div>
    )
}