export default class Course {
    constructor(name, par, location, length, holes, rating)
    {
        this.name = name
        this.par = par
        this.location = location
        this.length = length
        this.holes = holes
        this.rating = rating
    }
}