import { useEffect } from "react";
import { getAllData } from "../utils/manageData";


export default function Data()
{

    useEffect(() =>
    {
        const interval = setInterval(() => { handleDataLoad() }, 60000)
        return () => { clearInterval(interval) }
    })

    useEffect(() =>
    {
        console.log("handle")
        handleDataLoad()
    })

    async function handleDataLoad()
    {
        await getAllData()
    }
    

    return (
        <></>
    )
}