import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { getCourses, getEvents, getNews } from "../utils/manageData"
import { useURLID } from "../utils/useURLID"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function CoursePage()
{
    // Location & navigation
    const navigate = useNavigate()
    const id = useURLID()

    // Course variables
    const [course, setCourse] = useState(getCourses().find((c) => c.name === id))
    const [news, setNews] = useState(getNews())
    const [loading, setLoading] = useState(course === undefined)
    const [events, setEvents] = useState(getEvents().filter((e) => e.course === id))

    // Load data if necessary
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            // Check if location is available
            if (course === undefined)
            {
                setCourse(getCourses().find((c) => c.name === id))
                setNews(getNews())
                setEvents(getEvents().filter((e) => e.course === id))
                setLoading(false)
            }
        }, 1000)
        return () => { clearInterval(interval) }
    })

    // Render basic course info
    function renderCourseInfo() {
        return (
            <table>
                <tbody>
                    <tr>
                        <th>LOCATION</th>
                        <th>PAR</th>
                        <th>YDS</th>
                        <th>RATING</th>
                    </tr>
                    <tr>
                        <td>{course.location}</td>
                        <td>{course.par}</td>
                        <td>{course.length}</td>
                        <td>{course.rating}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    // Render events held
    function renderEventsHeld() {
        return (
            events.map((e) =>
            {
                let tag = "Completed"
                let tagColor = "green"
                let additionalClass = "green"
                if (e.date > new Date()) { tag = "Upcoming"; tagColor = "#25d"; additionalClass = "" }

                return (
                    <div className={`schedule-item ${additionalClass}`} onClick={() => navigate(`/event?id=${e.name}`)}>
                        
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <div>{e.name}</div>
                            <div style={{ color: tagColor }}>{tag}</div>

                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <div>{e.date.toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"}) }</div>

                        </Box>

                    </div>
                )
            })
        )
    }

    // Render news
    function renderNews() {
        return (
            news.map((article) => 
            {
                if (article.courses.includes(course.name))
                {
                    return (
                        <div className="news-card" onClick={() => { navigate(`/article?id=${article.title}`) }}>
                            <div>{article.title}</div>
                            <div className='news-card-date'>{article.date.toLocaleDateString()}</div>
                        </div>
                    )
                }
                return ( <></> )
            })
        )
    }

    // Render components
    return (

        <div className="container">

            <Box className="header">
                <ArrowBackIcon className="selectable" onClick={() => {navigate("/")}} />
                {course.name}
                <Box sx={{ width: "30px" }}></Box>
            </Box>

            <div className="page-subheader">Basic Info</div>

            { renderCourseInfo() }

            <div className="page-subheader">Events Held</div>

            { renderEventsHeld() }

            <div className="page-subheader">News</div>

            { renderNews() }

            <div className="page-subheader">Hole Info</div>

            <div className="scorecard" style={{ marginBottom: "0.2rem" }}>
                <div style={{ overflowX: "auto" }}>
                    <table style={{marginBottom: "0rem", overflowX: "auto", minWidth: "100%" }}>
                        
                        <thead>
                            
                            <tr>
                                <th className='course-scorecard-box'>Hole</th>
                                {course.holes.map((hole) =>
                                {
                                    if (hole.number === 9) {return (<><th className='course-scorecard-box'>{hole.number}</th><th className='course-scorecard-box'>IN</th></>)}
                                    if (hole.number === 18) {return (<><th className='course-scorecard-box'>{hole.number}</th><th className='course-scorecard-box'>OUT</th></>)}
                                    return ( <th className='course-scorecard-box'>{hole.number}</th>)
                                })}
                            </tr>
                            
                            <tr>
                                <th className='course-scorecard-box'>Yds</th>

                                {course.holes.map((hole) =>
                                {
                                    if (hole.number % 9 === 0) 
                                    {
                                        return (
                                            <>
                                                <th className='course-scorecard-box'>{hole.length}</th>
                                                <th className='course-scorecard-box'>{course.holes.slice(0, hole.number).reduce((acc, curr) => acc + curr.length, 0)}</th>
                                            </>
                                    )}

                                    return ( <th className='course-scorecard-box'>{hole.length}</th>)
                                })}
                            </tr>

                            <tr style={{ borderBottom: "1px solid #888" }}>
                                <th className='course-scorecard-box' >Par</th>

                                    {course.holes.map((hole) =>
                                    {
                                        if (hole.number % 9 === 0) 
                                        {
                                            return (
                                                <>
                                                    <th className='course-scorecard-box' >{hole.par}</th>
                                                    <th className='course-scorecard-box'>{course.holes.slice(0, hole.number).reduce((acc, curr) => acc + curr.par, 0)}</th>
                                                </>
                                        )}
                                        return ( <th className='course-scorecard-box'>{hole.par}</th>)
                                    })}
                            </tr>

                            <tr>
                                <th className='course-scorecard-box'>Handicap</th>

                                    {course.holes.map((hole) =>
                                    {
                                        if (hole.number % 9 === 0) {return (<><th className='course-scorecard-box'>{hole.handicap}</th><th className='course-scorecard-box'></th></>)}
                                        return ( <th className='course-scorecard-box'>{hole.handicap}</th>)
                                    })}
                            </tr>
                
                        </thead>
                    </table>
                </div>
            </div>

            <div className="page-subheader">Stats</div>



        </div>
    )
}