

import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { getEvents, getNews, getPlayers } from '../utils/manageData'
import { useURLID } from '../utils/useURLID'
import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function AttributesPage()
{

    // Location & navigation
    const navigate = useNavigate()
    const id = useURLID()

    // Event variable
    const [players, setPlayers] = useState(getPlayers().sort((a, b) => a.overall - b.overall).reverse())
    const [loading, setLoading] = useState(players === undefined)

    // Load data if necessary
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            // Check if location is available
            if (players === undefined)
            {
                setPlayers(getPlayers())
                setLoading(false)
            }
        }, 1000)
        return () => { clearInterval(interval) }
    })

    function sortTable(stat) {
        if (stat === "overall") { setPlayers([...players].sort((a, b) => a.overall - b.overall).reverse()) }
        else { setPlayers([...players].sort((a, b) => a.stats[stat].value - b.stats[stat].value).reverse()) }
    }

    if (loading) return (<></>)

    return (
        <div className="container">
            
            <Box className="header">
                <ArrowBackIcon className="selectable" onClick={() => {navigate("/")}} />
                Title
                <Box sx={{ width: "30px" }}></Box>
            </Box>

            <div className="page-subheader">Info</div>

            <div className="scorecard" style={{ marginBottom: "0.2rem" }}>
                <div style={{ overflowX: "auto" }}>

                    {players &&

                    <table style={{marginBottom: "0rem", overflowX: "auto", minWidth: "100%" }}>
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th className='selectable' onClick={() => {sortTable("overall")}} >OVR</th>
                                <th className='selectable' onClick={() => {sortTable("distance")}}>DIS</th>
                                <th className='selectable' onClick={() => {sortTable("accuracy")}}>ACC</th>
                                <th className='selectable' onClick={() => {sortTable("ballStriking")}}>BS</th>
                                <th className='selectable' onClick={() => {sortTable("putting")}}>PUT</th>
                                <th className='selectable' onClick={() => {sortTable("consistency")}}>CON</th>
                                <th className='selectable' onClick={() => {sortTable("chipping")}}>CHP</th>
                                <th className='selectable' onClick={() => {sortTable("clutch")}}>CLT</th>
                                <th className='selectable' onClick={() => {sortTable("swag")}}>SWG</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {players.map((p) => {
                                return (
                                    <tr>
                                        <td>{p.name}</td>
                                        <td>{p.overall}</td>
                                        <td>{p.stats.distance.value}</td>
                                        <td>{p.stats.accuracy.value}</td>
                                        <td>{p.stats.ballStriking.value}</td>
                                        <td>{p.stats.putting.value}</td>
                                        <td>{p.stats.consistency.value}</td>
                                        <td>{p.stats.chipping.value}</td>
                                        <td>{p.stats.clutch.value}</td>
                                        <td>{p.stats.swag.value}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                }
                </div>
            </div>

        </div>
    )
}