import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { getPlayers } from "../utils/manageData"
import { useEffect, useState } from "react"

export default function Latest() 
{

    const navigate = useNavigate()

    const [playerList, setPlayerList] = useState(getPlayers().sort((a, b) => a.powerIndex - b.powerIndex).reverse())
    const [scoreCountList, setScoreCountList] = useState(getPlayers().sort((a, b) => a.birdies - b.birdies).reverse())

    // Check if players are loaded, if not then reload
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            if (playerList.length < 1) { 
                setPlayerList(getPlayers().sort((a, b) => a.powerIndex - b.powerIndex).reverse())
                setScoreCountList(getPlayers().sort((a, b) => a.birdies - b.birdies).reverse())
            } 
        }, 1000)
        return () => { clearInterval(interval) }
    })

    function sortTable(score) {
        setScoreCountList([...scoreCountList].sort((a, b) => a[score] - b[score]).reverse())
        
    }

    function renderWeeklyMovers() {

        const playersToRender = []

        for (const p of playerList) {
            const change = p.overall - p.overallPrev

            if (Math.abs(change) > 0.5) { playersToRender.push(p) }
        }

        playersToRender.sort((a, b) => (a.overall - a.overallPrev) - (b.overall - b.overallPrev)).reverse()

        return (
            <>
            {playersToRender.length > 0 && <>
            <div className="page-subheader">Weekly Movers</div>
            <table>
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>OVERALL</th>
                    </tr>
                </thead>
                <tbody>
                    {playersToRender.map((p) => {
                        return (
                            <tr>
                                <td>{p.name}</td>
                                <td style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}>
                                    <div className='player-rating'>
                                        {Math.round(p.overall)} 

                                        <div style={{ display: "flex", border: "0px solid blue", alignItems: "center" }}>
                                            {(p.overall - p.overallPrev) < 0 ?
                                                <div className="arrow-down"></div> : <div className="arrow-up"></div>
                                            }
                                            {(p.overall - p.overallPrev) < 0 ?
                                                <div className='rating-change' style={{ color: "#d00" }}>{Math.round(Math.abs(p.overall - p.overallPrev))}</div> : <div className='rating-change' style={{ color: "#0d0" }}>{Math.round(Math.abs(p.overall - p.overallPrev))}</div>
                                            }
                                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </>
        }</>
        )
    }

    return (
        <div className="container">

            <Box fontFamily={"Rye"} sx={{ width: "100%", textAlign: "center", padding: "1rem", fontSize: 28, backgroundColor: "#efefef", borderBottom: "1px solid #c4c4c4", position: "sticky", top: 0, zIndex: 3 }} onClick={() => {document.documentElement.scrollTo({top: 0})}}>
                Official Tour
            </Box>

            <Box sx={{ width: "100%", textAlign: "center", fontSize: "12px", backgroundColor: "#efefef", boxShadow: "0 2px 2px -2px #333;", display: "flex", height: "56px", alignItems: "center", marginBottom: "0.2rem" }}>

                <div className="navbar-item" style={{ padding: "18px 8px" }} ></div>
                <div className={`navbar-item`} onClick={() => {navigate("/")}}>Overview</div>
                <div className={`navbar-item`} onClick={() => {navigate("/schedule")}}>Schedule</div>
                <div className={`navbar-item`} onClick={() => {navigate("/courses")}}>Courses</div>
                <div className={`navbar-item navbar-item-selected`} onClick={() => {navigate("/latest")}}>Latest</div>

            </Box>

            <div className="page-subheader">Power Rankings</div>

                <table>
                    <thead>
                        <tr>
                            <th>RNK</th>
                            <th>NAME</th>
                            <th>PWR INDEX</th>
                        </tr>
                    </thead>
                    <tbody>
                        {playerList.map((player, index) => 
                        {
                            return (
                                <tr className="selectable" onClick={() => {navigate(`/player?id=${player.name}`)}}>
                                    <td>{index + 1}</td>
                                    <td>{player.name}</td>
                                    <td>{player.powerIndex}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            
            {renderWeeklyMovers()}
            
            <div className="page-subheader">Score Counts</div>

            <table>
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th className="selectable" onClick={() => {sortTable("birdies")}}>BRD</th>
                        <th className="selectable" onClick={() => {sortTable("pars")}}>PAR</th>
                        <th className="selectable" onClick={() => {sortTable("bogeys")}}>BGY</th>
                        <th className="selectable" onClick={() => {sortTable("doubleBogeys")}}>DBL</th>
                        <th className="selectable" onClick={() => {sortTable("tripleBogeys")}}>TRP</th>
                        <th className="selectable" onClick={() => {sortTable("quadBogeys")}}>QD</th>
                    </tr>
                </thead>
                <tbody>
                    {scoreCountList.map((player, index) => 
                    {
                        return (
                            <tr>
                                <td>{player.name}</td>
                                <td>{player.birdies}</td>
                                <td>{player.pars}</td>
                                <td>{player.bogeys}</td>
                                <td>{player.doubleBogeys}</td>
                                <td>{player.tripleBogeys}</td>
                                <td>{player.quadBogeys}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div style={{ marginTop: "2rem" }}></div>

        </div>
    )
}