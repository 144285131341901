import { Box, Typography } from "@mui/material";


export default function PlayerBios()
{

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", gap: "1rem", overflow: "scroll", marginTop: "2rem", minHeight: "0px", position: "absolute", top: 0, bottom: 0, left: 0 }}>
            <div style={{ fontFamily: "ESP_Bold_Italic, sans-serif", justifyContent: "center", display: "flex", marginTop: '0vh', fontSize: 24, color: "white" }}>Player Bios</div>

            <Box sx={{ display: "1 1 auto", width: "90%", minHeight: "0px"}}>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>Mikey</Typography>
            <Typography variant="p1">
                Mikey is more focused on fantasy football than golf and refuses to go to the range. God himself
                couldn’t fix his swing, but he knew how to roll some putts. In a scramble format, he’s a guy you
                want but in individual play – he’s getting A LOT of strokes. With the number of strokes he will be
                given, if Mikey can figure his swing out he could be a surprise finish at the near of the top
                leaderboard.
            </Typography>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>Sahil</Typography>
            <Typography variant="p1">
                This man is very disinterested in golf but more interested in Memorial basketball. His swing has
                some potential but needs a lot of improvement and time at the range. He will give you the ‘once
                in a blue moon’ shot but the rest of the shots typically aren’t ideal. On the green, he is a
                formidable option and on the right day, he can sink some big-time putts. If Sahil can channel his
                inner ‘memorial’ on the golf course, he will be able to rack up some points and be a contender
                in this league’s season.
            </Typography>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>Jake</Typography>
            <Typography variant="p1">
                His biggest potential strength might be his biggest downfall. A hard offseason with the ‘road to
                250’ has led to some seen power on the box. But with this new strength, has come some lack of
                mobility some would say. His heart is truly in golf but seems to be more for the cigars in the cart
                than trying to hit some quality shots. When he’s on, he hits irons and drives as far as anyone but
                some would say that the days of him being on are ‘few and far between’. Jake is expected to be
                all over the leaderboard with some 1 st and 8 th -place finishes but hope that much of the 1 st -place
                finishes will lead him to potential contention to win this season.
            </Typography>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>Christian</Typography>
            <Typography variant="p1">
                Formerly known as ‘Butter Draw’, Christian’s game comes in waves – some days he’s playing
                that draw or that nice fade; while some days he’s playing the impressive ‘snap hook’. A true
                definition of an athlete, Chrisitan is now referred to as ‘Mr. Potential’ – promising aspects to his
                game, just can never seem to put it together. He needs to work on his touch around the green,
                but when he’s on he is a force to be reckoned with. How often he can be consistent will
                determine his success in this year’s season, and if he hits his stride at the right times he could be seen as #1 on the
                leaderboard throughout the year.
            </Typography>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>Koose</Typography>
            <Typography variant="p1">
                Earning the official and well-deserved nickname of ‘Mr. Exponential’, Koose’s game has been
                nothing short of that –starting less than a year ago shooting 130’s to consistently being around
                100. Koose is one of the purest irons players in the league and plays his patent ‘fade’ with
                occasional draws. He puts as much time into the range as anyone and has the desire to be great
                like ‘Tiger’. Day by day his skill is getting better but he will sprinkle in the occasional abysmal day
                here and there. If he can find a fairway and keeps improving his game, Koose is a true threat to
                win the league this season.
            </Typography>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>Ty</Typography>
            <Typography variant="p1">
                One of the purest and rawest athletes to play golf, Ty continues to perfect the details of the
                sport. Ty leans into his baseball background and uses it as an asset rather than a liability. Ty
                plays a patent fade and has some ridiculous finishes to his swing on the tee box. He is consistent
                in the game and insists on always playing the tips, even to his detriment. One of the most
                complete players in the league, anything other than a first-place finish would be a
                disappointment.
            </Typography>

            <Typography variant="h5" fontWeight={600} sx={{ marginTop: "2rem" }}>John</Typography>
            <Typography variant="p1">
                John has been playing consistently for years and it truly shows on the course. John is at the top
                of the conversation when it comes to chipping putting, and ball striking. His drivers typically
                fund the fairway but the distance that they go can cause some concern. John can play many
                different swings and some would say he is the best outright player in the league. The man is the
                definition of a ‘walking two-putt’ if he is anything more than 15 feet out and within 15 feet, he
                always has a chance to put one in the hole. John hopes that his consistent play will lead to
                consistent finishes, putting him in a great spot to win the league this year.
            </Typography>

            <Box sx={{ height: "70px" }}></Box>

            </Box>
        </Box>
    )
}

