import { useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { getAllData, getEvents, getNews, getPlayers } from '../utils/manageData'

export default function Home()
{

    const navigate = useNavigate()

    const [playerList, setPlayerList] = useState(getPlayers())
    const [event, setEvent] = useState(getEvents()[0])
    const [news, setNews] = useState(getNews())

    const tag = "Upcoming"
    const tagColor = "#25d"
    let additionalClass = "blue"

    console.log(event)

    // Check if players are loaded, if not then reload
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            if (playerList.length < 1) { 
                setPlayerList(getPlayers())
                setEvent(getEvents()[0]) 
                setNews(getNews())
            } 
        }, 1000)
        return () => { clearInterval(interval) }
    })

    return (

        <div className="container">

            <Box fontFamily={"Rye"} sx={{ width: "100%", textAlign: "center", padding: "1rem", fontSize: 28, backgroundColor: "#efefef", borderBottom: "1px solid #c4c4c4", position: "sticky", top: 0, zIndex: 3 }} onClick={() => {document.documentElement.scrollTo({top: 0})}}>
                Official Tour
            </Box>

            <Box sx={{ width: "100%", textAlign: "center", fontSize: "12px", backgroundColor: "#efefef", boxShadow: "0 2px 2px -2px #333;", display: "flex", height: "56px", alignItems: "center", marginBottom: "0.2rem" }}>

                <div className="navbar-item" style={{ padding: "18px 8px" }} ></div>
                <div className={`navbar-item navbar-item-selected`} onClick={() => {navigate("/")}}>Overview</div>
                <div className={`navbar-item`} onClick={() => {navigate("/schedule")}}>Schedule</div>
                <div className={`navbar-item`} onClick={() => {navigate("/courses")}}>Courses</div>
                <div className={`navbar-item`} onClick={() => {navigate("/latest")}}>Latest</div>

            </Box>

            <div className="page-subheader">Leaderboard</div>

            {playerList.length > 0 && 

            <table>
                <thead>
                    <tr>
                        <th className='column-1'>NAME</th>
                        <th className='column-2'>PTS</th>
                        <th>WINNINGS</th>
                    </tr>
                </thead>
                <tbody>
                {playerList.map((player) =>
                {
                    return (
                        <tr className='selectable' onClick={() => {navigate(`/player?id=${player.name}`)}}>
                            <td key={player.name} className='column-1'>{player.name}</td>
                            <td key={player.points} className='column-2'>{player.points}</td>
                            <td key={player.bio}>{player.winnings}</td>
                        </tr>
                    )
                })}
                </tbody>


            </table> }

            <div className="page-subheader">Next Event</div>

            {event !== undefined &&
            <div className={`schedule-item ${additionalClass}`} onClick={() => navigate(`/event?id=${event.name}`)}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div>{event.name}</div>
                    <div style={{ color: tagColor }}>{tag}</div>

                </Box>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div>{event.date.toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"}) }</div>

                </Box>

            </div>
            }

            <div className="page-subheader">News</div>

            { news.map((article) => 
            {
                return (
                    <div className="news-card" onClick={() => { navigate(`/article?id=${article.title}`) }}>
                        <div>{article.title}</div>
                        <div className='news-card-date'>{article.date.toLocaleDateString()}</div>
                    </div>
                )
            }) }

            <div style={{ marginTop: "2rem" }}></div>

        </div>
    )
}







    // const renderer = ({ days, hours, minutes, seconds, completed }) =>
    // {
    //     if (completed) {
    //       // Render a completed state
    //       return (
    //         <></>
    //       )
    //     } else {
    //       // Render a countdown
    //       return (
    //         <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: '50%' }}>
    //             <div>{days} Days</div>
    //             <div>{hours} Hours</div>
    //             <div>{minutes} Minutes</div>
    //             <div>{seconds} Seconds</div>
    //         </div>
    //       )
    //     }
    // }