import { useNavigate } from "react-router-dom"
import { getNews } from "../utils/manageData"
import { useURLID } from "../utils/useURLID"
import { useEffect, useState } from "react"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function Article()
{
    const navigate = useNavigate()
    const id = useURLID()
    const [article, setArticle] = useState()
    const [loading, setLoading] = useState(true);

    // Check if article are loaded, if not then reload
    useEffect(() =>
    {
        const interval = setInterval(() => 
        { 
            if (article == undefined) { 
                setArticle(getNews().find((a) => a.title === id))
                setLoading(false)
            } 
        }, 1000)
        return () => { clearInterval(interval) }
    })

    // Render nothing if loading
    if (loading) return <div></div>

    // Render components
    return (
        <div className="container">

            <div className="header">
                <ArrowBackIcon className="selectable" onClick={() => {navigate("/")}} />
                Official Tour
                <div sx={{ width: "30px" }}></div>
            </div>

            <div className="article-container">
                <div className="article-title">{article.title}</div>
                <div className="article-sub-title">{article.date.toLocaleString()}</div>
                <div className="article-divider"></div>
                <div className="article-body" id="bodyaa"dangerouslySetInnerHTML={{__html: article.body}}></div>
            </div>

            <div className="article-tag">
                Players:  &nbsp;
                {article.players.map((player, index) =>
                {
                    return (
                        <>
                        <em className="article-tag-item" onClick={() => navigate(`/player?id=${player}`)}>
                            {player}
                            {index < article.players.length - 1 && <>,</>}
                        </em>
                        &nbsp;
                        </>
                    )
                })}
            </div>

            <div className="article-tag">
                Events:  &nbsp;
                {article.events.map((event) =>
                {
                    return (
                        <>
                        <em className="article-tag-item" onClick={() => navigate(`/event?id=${event}`)}>
                            {event}
                        </em>
                        &nbsp;
                        </>
                    )
                })}
            </div>

            <div className="article-tag">
                Courses:  &nbsp;
                {article.courses.map((course) =>
                {
                    return (
                        <>
                        <em className="article-tag-item" onClick={() => navigate(`/course-profile?id=${course}`)}>
                            {course} 
                        </em>
                        &nbsp;
                        </>
                    )
                })}
            </div>

            <div style={{ marginTop: "1.2rem" }}></div>

        </div>
    )
}